import React, { Component } from 'react'
import logo from '../../images/logo.png'
import MaskedInput from 'antd-mask-input'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'antd'
import { AgreementModal } from '../../modals'
import { Timer, CodeInput } from '../../components'
import { AppState } from '../../store'
import { RouteComponentProps } from 'react-router-dom'
import Stores from '../../components/Stores'
import './style.less'
import { checkCodeRequest, demoTokenRequest, sendCodeRequest } from '../../store/reducks/auth/auth.actions'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

interface Props extends RouteComponentProps, DispatchProps, StateProps {}

interface State {
  showAgreementModal: boolean
  phone: string
  phoneInvalid: boolean
  code: string
  stage: number
  error: boolean
  canSend: boolean
  sending: boolean
}

class LoginView extends Component<Props, State, Props> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showAgreementModal: false,
      phone: '',
      phoneInvalid: true,
      code: '',
      stage: 1,
      error: true,
      canSend: false,
      sending: false,
    }
  }

  componentDidMount() {
    if (!!this.props.auth.token) {
      this.props.history.push('/')
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const phoneValid = this.parsePhone().length == 10
    if (prevState.phoneInvalid && phoneValid) {
      this.setState({ phoneInvalid: false })
    }
    if (!prevState.phoneInvalid && !phoneValid) {
      this.setState({ phoneInvalid: true })
    }
    const { auth, error } = this.props
    if (auth.stage == 'DEMO' && auth.token && !prevProps.auth.token) {
      this.props.history.replace('/')
    }
    if (prevProps.auth.fetching && !auth.fetching && prevState.stage == 1 && auth.stage != 'LOGOUT') {
      if (!error) {
        this.setState({ stage: 2, canSend: false, sending: false })
      }
    }
    if (prevProps.auth.fetching && !auth.fetching && prevState.stage == 2 && prevState.sending) {
      if (!error) {
        this.setState({ canSend: false, sending: false })
      }
    }
    if (prevProps.auth.fetching && !auth.fetching && prevState.stage == 2 && !prevState.sending) {
      if (!error) {
        this.props.history.push('/')
      }
    }
    const code = this.state.code
    const prevCode = prevState.code
    if (prevCode != code && code.length == 4 && prevState.stage == 2) {
      this.props.authCheckCodeAction({ phone: this.parsePhone(), code })
    }
  }

  parsePhone = (): string => {
    return this.state.phone.replace(/[^0-9]+/g, '')
  }

  sendCode() {
    if (this.state.phoneInvalid) return
    this.setState({ sending: true })
    this.props.authSendCodeAction({ phone: this.parsePhone() })
  }

  render() {
    const { phone, phoneInvalid, showAgreementModal, stage, canSend } = this.state
    return (
      <div className="login">
        <div className="main">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="form">
            <div className="body">
              <h2>Вход в личный кабинет</h2>
              {stage == 1 ? (
                <>
                  <p className="description">
                    Пожалуйста, зарегистрируйтесь для получения
                    <br />
                    информации о клинике и записи на прием
                  </p>
                  <div className="phone-input">
                    <MaskedInput
                      prefix="+7"
                      value={phone}
                      mask="(111) 111-11-11"
                      placeholder="номер телефона"
                      onPressEnter={() => {
                        this.sendCode()
                      }}
                      onChange={(e) => {
                        this.setState({ phone: e.target.value })
                      }}
                      size="large"
                    />
                    <Button
                      size="large"
                      type="primary"
                      disabled={phoneInvalid}
                      onClick={() => {
                        this.sendCode()
                      }}
                    >
                      Отправить код
                    </Button>
                  </div>
                  <p
                    className="agreement"
                    onClick={() => {
                      this.setState({ showAgreementModal: true })
                    }}
                  >
                    Нажимая на кнопку "отправить смс с кодом", я даю согласие на{' '}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({ showAgreementModal: true })
                      }}
                    >
                      обработку персональных данных
                    </a>
                  </p>
                  <p
                    className="demo-login"
                    onClick={() => {
                      this.props.demoTokenRequest()
                    }}
                  >
                    {'ДЕМО-РЕЖИМ'}
                    </p>
                </>
              ) : (
                <>
                  <p className="description">
                    Мы отправили СМС с кодом на
                    <br />
                    номер <strong>+7 {phone}.</strong>{' '}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({ stage: 1, phone: '', code: '' })
                      }}
                    >
                      Изменить
                    </a>
                  </p>
                  <CodeInput
                    className="code"
                    onChange={(code) => {
                      this.setState({ code })
                    }}
                  />
                  <Button
                    className="send"
                    disabled={!canSend}
                    type="link"
                    onClick={() => {
                      this.setState({ sending: true })
                      this.props.authSendCodeAction({ phone: this.parsePhone() })
                    }}
                  >
                    {!canSend ? (
                      <>
                        Отправить повторно через&nbsp;
                        <Timer
                          duration={60}
                          onDone={() => {
                            this.setState({ canSend: true })
                          }}
                        />
                      </>
                    ) : (
                      <>Отправить повторно</>
                    )}
                  </Button>
                </>
              )}
            </div>
            <div className="footer">
              <p>Также вы можете воспользоваться нашим мобильным приложением</p>
              <Stores />
            </div>
          </div>
        </div>
        <AgreementModal
          onCancel={() => {
            this.setState({ showAgreementModal: false })
          }}
          show={showAgreementModal}
        />
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
  error: state.auth.error,
})

const mapDispatchToProps = {
  authSendCodeAction: sendCodeRequest,
  authCheckCodeAction: checkCodeRequest,
  demoTokenRequest,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginView))
