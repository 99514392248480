import React, { Component } from 'react'
import Template from '../../components/Template'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import RecordFormatModal from '../../modals/RecordFormatModal'
import {
  recordFormatSetAction,
  recordPatientSetAction,
  recordClearAction,
  recordStageSetAction,
  recordCatalogSetAction,
  recordServiceSetAction,
  recordDateSetAction,
  recordNewPatientSetAction,
  recordDoctorSetAction,
  recordTypeSetAction,
  recordDateTimeSetAction,
  recordAddressSetAction,
  recordRegisterInfoSetAction,
  recordStateSetAction,
} from '../../store/reducks/record/record.actions'
import { AppState } from '../../store'
import RecordingSidebar from '../../components/sidebars/RecordingSidebar'
import RecordingStage3 from '../../components/Recording/Stage3'
import { IPatient, IPricelistCatalog } from '../../store/types'
import { pricelistCatalogServivesGetAction, pricelistCatalogsGetAction } from '../../store/reducers/pricelist'
import { Tabs } from 'antd'
import RecordingStage4 from '../../components/Recording/Stage4'
import './style.less'
import RecordingStage5 from '../../components/Recording/Stage5'
import { patientsByphoneOrderRequest, patientsByphoneRequest } from '../../store/reducks/patients/patients.byphone.actions'
import { pricelistCatalogServicesRequest } from '../../store/reducks/pricelist/pricelist.catalog_services.actions'
import { pricelistCatalogsRequest } from '../../store/reducks/pricelist/pricelist.catalogs.actions'
import { scheduleByDateDivisionsDatetimesRequestAction } from '../../store/reducks/schedule/schedule.bydate.divisions_datetimes.actions'
import { scheduleByDateDivisionsDoctorsRequestAction } from '../../store/reducks/schedule/schedule.bydate.divisions_doctors.actions'
import { futureAppointmentsByServiceClearAction, futureAppointmentsByServiceRequestAction } from '../../store/reducks/appointments/appointments.future.byservice.actions'
import { CatalogItem, CatalogPreview, Price } from '../../api/pricelist.types'
import { scheduleByDoctorDoctorsRequestAction } from '../../store/reducks/schedule/schedule.bydoctor.doctors.actins'
import { scheduleByDoctorDivisionsDatetimesRequestAction } from '../../store/reducks/schedule/schedule.bydoctor.divisions_datetimes.actions'
import { DoctorPreview } from '../../api/doctor.types'
import RecordingStage2 from '../../components/Recording/Stage2'
import RecordingStage6 from '../../components/Recording/Stage6'
import { pricelistPrepareInfoModalSetAction, pricelistPrepareInfoRequestAction } from '../../store/reducks/pricelist/pricelist.prepare_info.actions'
import { registerAppointmentByMisTokenRequestAction, registerAppointmentByPhoneRequestAction, registerAppointmentMainRequestAction } from '../../store/reducks/register/register.appointment.actions'
import { registerInfoForNewPatientRequestAction, registerInfoForPatientRequestAction } from '../../store/reducks/register/register.info.actions'
import { RegisterInfo } from '../../api/register.types'
import Button from 'antd/es/button'
import { pricelistDoctorServicesRequestAction } from '../../store/reducks/pricelist/pricelist.doctor_services.actions'
import { Patient } from '../../api/patient.types'
import { NewPatient } from '../../store/reducks/record/record.types'
import {Appointment} from "../../api/appointment.types";

const { TabPane } = Tabs

const stages = [
  'Формат записи',
  'Выбор пациента',
  'Выбор специальности',
  'Выбор услуги',
  'Дата, время, врач',
  'Подтверждение записи',
]

const stageTitles = [
  'Выберите формат записи',
  'Выберите пациента',
  'Выберите специальность',
  'Выберите услугу',
  'Выберите дату, время, врача',
  'Подтверждение записи',
]

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

interface Props extends RouteComponentProps, DispatchProps, StateProps { }

interface State {
  showRecordFormatModal: boolean
  showPatientListModal: boolean
  catalogsLoaded: boolean
  catalogServicesLoaded: boolean
  doctorServicesLoaded: boolean
  activeCatalogKey: string
  activeServicesKey: string
  datetimesLoaded: boolean
  doctorsLoaded: boolean
  doctorsByDateLoaded: boolean
  appointmentRegistered: boolean
  registerInfoLoaded: boolean
  datetimesByDoctorLoaded: boolean
  newPatientStageTitlesShow: boolean
}

class RecordingView extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showRecordFormatModal: false,
      showPatientListModal: false,
      catalogsLoaded: false,
      catalogServicesLoaded: false,
      doctorServicesLoaded: false,
      activeCatalogKey: '0',
      activeServicesKey: '0',
      datetimesLoaded: false,
      doctorsLoaded: false,
      doctorsByDateLoaded: false,
      appointmentRegistered: false,
      registerInfoLoaded: false,
      datetimesByDoctorLoaded: false,
      newPatientStageTitlesShow: false,
    }
  }

  componentDidMount() {
    this.onStart()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.patients_fetching && !this.props.patients_fetching && this.props.stage == 2) {
      if (!this.props.error) {
        this.setState({ showPatientListModal: true })
      }
    }
    if (prevProps.stage == 3 && this.props.stage == 2) {
      this.setState({ showPatientListModal: true })
    }
    if (prevProps.pricelist.catalogs.fetching && !this.props.pricelist.catalogs.fetching && this.props.stage == 3) {
      if (!this.props.error) {
        this.setState({ catalogsLoaded: true })
      }
    }
    if (prevProps.pricelist.catalog_services.fetching && !this.props.pricelist.catalog_services.fetching && this.props.stage == 4) {
      if (!this.props.error) {
        this.setState({ catalogServicesLoaded: true })
      }
    }
    if (prevProps.pricelist.doctor_services.fetching && !this.props.pricelist.doctor_services.fetching && this.props.stage == 4) {
      if (!this.props.error) {
        this.setState({ doctorServicesLoaded: true })
      }
    }
    if (prevProps.datetimes_bydate.fetching && !this.props.datetimes_bydate.fetching && this.props.stage == 5) {
      if (!this.props.error) {
        this.setState({ datetimesLoaded: true })
      }
    }
    if (prevProps.doctors_bydoctor.fetching && !this.props.doctors_bydoctor.fetching && this.props.stage == 5) {
      if (!this.props.error) {
        this.setState({ doctorsLoaded: true })
      }
    }
    if (prevProps.doctors_bydate.fetching && !this.props.doctors_bydate.fetching && this.props.stage == 5) {
      if (!this.props.error) {
        this.setState({ doctorsByDateLoaded: true })
      }
    }
    if (prevProps.appointment_register.fetching && !this.props.appointment_register.fetching && !this.props.appointment_register.error) {
      this.setState({ appointmentRegistered: true })
    }
    if (prevProps.register_info.fetching && !this.props.register_info.fetching && !this.props.register_info.error) {
      this.setState({ registerInfoLoaded: true })
      this.props.recordRegisterInfoSetAction(this.props.register_info.registerInfo as RegisterInfo)
    }
    if (prevProps.datetimes_bydoctor.fetching && !this.props.datetimes_bydoctor.fetching && !this.props.datetimes_bydoctor.error) {
      this.setState({ datetimesByDoctorLoaded: true })
    }

    const { record } = this.props
    if (record.stage == 5 && record.service && !prevProps.record.service && record.doctor) {
      this.fetchDatetimesByDoctorForStage5(record.doctor)
    }
  }

  private onStart = () => {
    const { stage, record } = this.props
    if (stage === 5) {
      // "перезаписаться"
      this.fetchDataForStage5(record.service as Price)
    } else if (stage === 2) {
      // "записаться" к врачу
      this.fetchDataForStage2()
    } else {
      this.props.recordClearAction()
      this.setState({ showRecordFormatModal: true })
    }
  }

  goBack = (stage: number) => {
    const { record, patient, catalog } = this.props
    switch (stage) {
      case 6:
        this.props.recordStageSetAction(5)
        this.setState({ registerInfoLoaded: false })
        break;
      case 5:
        if (record.is_repeat || (!catalog || Object.keys(catalog).length == 0)) {
          // aka "case 4", потому что не выбран "catalog"
          this.setState({ catalogServicesLoaded: false, doctorServicesLoaded: false, activeCatalogKey: '0', activeServicesKey: '0' })
          this.props.recordCatalogSetAction(null)
          this.fetchDataForStage3()
          this.props.recordStateSetAction({
            stage: 3,
            is_repeat: false,
            to_doctor: false,
            type: 'bydate',
          })
          break
        }
        this.setState({ doctorsLoaded: false, datetimesLoaded: false, activeCatalogKey: '0', activeServicesKey: '0' })
        this.props.recordServiceSetAction(null)
        if (!record.to_doctor && catalog && Object.keys(catalog).length > 0) {
          this.fetchDataForStage4(catalog)
        }
        if (record.to_doctor) {
          this.props.recordStateSetAction({ stage: 4 })
        } else {
          this.props.recordStateSetAction({ stage: 4, type: 'bydate' })
        }
        break
      case 4:
        if (record.to_doctor) {
          // в мобилке так, но это не точно
          this.props.recordStateSetAction({ to_doctor: false, type: 'bydate' })
        }
        this.setState({ catalogServicesLoaded: false, doctorServicesLoaded: false, activeCatalogKey: '0', activeServicesKey: '0' })
        this.props.recordCatalogSetAction(null)
        this.fetchDataForStage3()
        this.props.recordStageSetAction(3)
        break
      case 3:
        this.setState({ showPatientListModal: false, catalogsLoaded: false, activeCatalogKey: '0' })
        this.props.recordPatientSetAction(null)
        this.fetchDataForStage2()
        this.props.recordStageSetAction(2)
        break
      case 2:
        this.setState({ showPatientListModal: false, newPatientStageTitlesShow: false })
        this.props.recordPatientSetAction(null)
        this.props.recordNewPatientSetAction(null)
        this.props.recordFormatSetAction(null)
        this.props.recordStageSetAction(1)
        break
    }
  }

  getSubTitle = (stage: number): string => {
    const { newPatientStageTitlesShow } = this.state
    if (newPatientStageTitlesShow && stage == 2) return `${stage}. Новый пациент`
    return `${stage}. ${stageTitles[stage - 1] || ''}`
  }

  private renderSubTitle = (stage: number) => {
    const text = this.getSubTitle(stage)
    return <div className="name">
      <span>{text}</span>
      {stage == 5 && <Button type="default" onClick={this.toggleRecordType}>{this.getRecordTypeText()}</Button>}
      {stage != 5 && <Button type="default" style={{ visibility: 'hidden' }} />}
    </div>
  }

  private toggleRecordType = () => {
    const { record } = this.props
    if (record.type == 'bydate') {
      this.onRecordTypeChange('bydoctor')
    }
    if (record.type == 'bydoctor') {
      this.onRecordTypeChange('bydate')
    }
  }

  private getRecordTypeText = () => {
    const { record } = this.props
    if (record.type == 'bydate') {
      return 'Записаться к врачу'
    }
    if (record.type == 'bydoctor') {
      return 'Записатсья по дате'
    }
  }

  renderTabs(catalogs: IPricelistCatalog[]) {
    if (catalogs.length) {
      return (
        <Tabs
          onChange={(activeCatalogKey) => {
            this.setState({ activeCatalogKey })
          }}
        >
          {catalogs.map((catalog: IPricelistCatalog, index: number) => {
            return <TabPane tab={catalog.name} key={index.toString()} />
          })}
        </Tabs>
      )
    }
  }

  private fetchDataForStage2 = () => {
    this.props.patientsByPhoneGetAction()
  }

  private fetchDataForStage3 = () => {
    this.props.pricelistCatalogsRequest()
  }

  private fetchDataForStage4 = (catalog: CatalogPreview) => {
    const { record } = this.props
    if (record.patient) {
      const { medic_user_id } = record.patient
      this.props.pricelistCatalogServicesRequest({ catalog_id: catalog.catalog_id, medic_user_id })
    } else if (record.new_patient) {
      this.props.pricelistCatalogServicesRequest({ catalog_id: catalog.catalog_id, ...record.new_patient })
    }
  }
  private fetchDataForStage4ByDoctor = (params: { patient?: Patient; new_patient?: NewPatient }) => {
    const { record } = this.props
    if (record.doctor) {
      const { doctor_id = record.doctor.doctor_employer_id } = record.doctor
      if (params.patient) {
        const { medic_user_id } = params.patient
        this.props.pricelistDoctorServicesRequestAction({ doctor_id, medic_user_id })
      } else if (params.new_patient) {
        this.props.pricelistDoctorServicesRequestAction({ doctor_id, ...params.new_patient })
      }
    }
  }

  private fetchDataForStage5 = (service: Price) => {
    const { record } = this.props
    const { service_id, pls_id } = service
	  
	// делаем запрос на получение добавленных родственников
	this.props.patientsByPhoneGetAction()
	 
    if (record.type == 'bydate' && record.patient) {
      const { medic_user_id } = record.patient
      this.props.scheduleByDateDivisionsDatetimesRequestAction({ service_id, medic_user_id })
      this.props.appointmentsFutureByServiceRequestAction({ service_id, medic_user_id })
	    
	  this.props.patients.byphone.added.users.map((user:  Patient)=>{
		  const { medic_user_id } = user
		  this.props.appointmentsFutureByServiceRequestAction({ service_id, medic_user_id}, true)
	  })
	   
    } else if (record.type == 'bydate' && record.new_patient) {
      this.props.scheduleByDateDivisionsDatetimesRequestAction({ service_id, ...record.new_patient })
      // для нового пользователя занулить список предстоящих приемов в redux'е
      this.props.futureAppointmentsByServiceClearAction()
    } else if (record.type == 'bydoctor' && record.patient) {
      const { medic_user_id } = record.patient
      this.props.scheduleByDoctorDoctorsRequestAction({ pls_id })
      this.props.appointmentsFutureByServiceRequestAction({ service_id, medic_user_id })
    } else if (record.type == 'bydoctor' && record.new_patient) {
      this.props.scheduleByDoctorDoctorsRequestAction({ pls_id })
      // для нового пользователя занулить список предстоящих приемов в redux'е
      this.props.futureAppointmentsByServiceClearAction()
    }
  }

  private fetchDoctorsByDateForStage5 = (date: string) => {
    const { record } = this.props
    if (record.type != 'bydate') {
      // TODO: что-то не так
      return
    }
    const { service_id } = record.service as Price
    if (record.patient) {
      const { medic_user_id } = record.patient
      this.props.scheduleByDateDivisionsDoctorsRequestAction({ date, service_id, medic_user_id })
    } else if (record.new_patient) {
      this.props.scheduleByDateDivisionsDoctorsRequestAction({ date, service_id, ...record.new_patient })
    }
  }

  private fetchDatetimesByDoctorForStage5 = (doctor: DoctorPreview) => {
    const { record } = this.props
    if (record.type != 'bydoctor') {
      // TODO: что-то не так
      return
    }
    const { service_id } = record.service as Price
    const { doctor_id } = doctor
    if (record.patient) {
      const { medic_user_id } = record.patient
      this.props.scheduleByDoctorDivisionsDatetimesRequestAction({ service_id, medic_user_id, doctor_id })
    } else if (record.new_patient) {
      this.props.scheduleByDoctorDivisionsDatetimesRequestAction({ service_id, doctor_id, ...record.new_patient })
    }
  }

  private fetchRegisterInfoForStage6 = () => {
    const { record } = this.props
    const { patient, new_patient, service, doctor, datetime } = record
    if (patient) {
      this.props.registerInfoForPatientRequestAction({
        medic_user_id: patient.medic_user_id,
        service_id: service?.service_id as number,
        doctor_id: doctor?.doctor_id as number,
        rec_date: datetime as string,
      })
    } else if (new_patient) {
      this.props.registerInfoForNewPatientRequestAction({
        service_id: service?.service_id as number,
        doctor_id: doctor?.doctor_id as number,
        rec_date: datetime as string,
        sex: new_patient.sex,
        birthdate: new_patient.birthdate,
      })
    }
  }

  private registerAppointment = () => {
    const { record } = this.props
    const { doctor, service } = record
    const params = {
      doctor_id: doctor?.doctor_id as number,
      service_id: service?.service_id as number,
    }
    if (record.new_patient) {
      this.props.registerAppointmentByPhoneRequestAction({
        ...record.new_patient,
        ...params,
        rec_date: record.datetime as string,
      })
    } else if (record.patient) {
      const { medic_user_id, relation } = record.patient
      if (relation == 'phone') {
        this.props.registerAppointmentByPhoneRequestAction({
          ...params,
          medic_user_id,
          rec_date: record.datetime as string,
        })
      } else {
        this.props.registerAppointmentMainRequestAction({
          ...params,
          medic_user_id,
          doctor_date_time: record.datetime as string,
        })
      }
    }
  }

  private getTabs = () => {
    const { stage } = this.props
    if (stage == 3) {
      const { catalogsLoaded } = this.state
      const { catalogsByDepartments } = this.props.catalogs
      return catalogsLoaded ? catalogsByDepartments.map(d => d.name) : undefined
    }
    if (stage == 4) {
      const { catalogServicesLoaded, doctorServicesLoaded } = this.state
      return (catalogServicesLoaded || doctorServicesLoaded) ? ['Самостоятельная запись', 'Все услуги'] : undefined
    }
    return undefined
  }

  private onTabChange = (key: string) => {
    const { stage } = this.props
    if (stage == 3) {
      const { catalogsLoaded } = this.state
      if (catalogsLoaded) {
        this.setState({ activeCatalogKey: key })
      }
    }
    if (stage == 4) {
      const { catalogServicesLoaded, doctorServicesLoaded } = this.state
      if (catalogServicesLoaded || doctorServicesLoaded) {
        this.setState({ activeServicesKey: key })
      }
    }
  }

  private onRecordTypeChange = (type: 'bydate' | 'bydoctor') => {
    const { record } = this.props

    this.props.recordTypeSetAction(type)
    this.props.recordDateTimeSetAction(null)
    this.props.recordDoctorSetAction(null)
    this.setState({ datetimesLoaded: false, doctorsLoaded: false }, () => {
      this.fetchDataForStage5(record.service as Price)
    })
  }

  render() {
    const {
      // patientsByPhoneGetAction,
      patients,
      history,
      record,
      // catalogs,
      stage,
      // patient,
      catalog,
      catalog_services,
      doctor_services,
      recordCatalogSetAction,
      recordStageSetAction,
      recordFormatSetAction,
      recordPatientSetAction,
      recordNewPatientSetAction,
      recordServiceSetAction,
      // pricelistCatalogsGetAction,
      // patientsByPhoneSortAction,
      // pricelistCatalogServivesGetAction,
    } = this.props
    const {
      showPatientListModal,
      catalogsLoaded,
      catalogServicesLoaded,
      doctorServicesLoaded,
      activeCatalogKey,
      datetimesLoaded,
      doctorsByDateLoaded,
      activeServicesKey,
      doctorsLoaded,
      appointmentRegistered,
      datetimesByDoctorLoaded,
      newPatientStageTitlesShow,
    } = this.state
    const { catalogsByDepartments } = this.props.catalogs
    const stage4_services = record.type == 'bydate' ? catalog_services : doctor_services
    return (
      <Template
        className="recording"
        title="Запись на прием"
        subTitle={[2, 3, 4, 5, 6].includes(stage) && this.renderSubTitle(stage)}
        onSubTitleBack={() => {
          this.goBack(stage)
        }}
        tabs={this.getTabs()}
        onTabChange={this.onTabChange}
        sidebar={<RecordingSidebar stages={stages} stage={stage} />}
        currentItem="recording"
      >
        {stage == 1 && (
          <RecordFormatModal
            show
            onSelect={(format) => {
              this.setState({ showRecordFormatModal: false })
              if (format == 'offline') {
                recordFormatSetAction(format)
                recordStageSetAction(2)
                this.fetchDataForStage2()
              } else {
                history.push('/telemedicine')
              }
            }}
            onCancel={() => {
              this.setState({ showRecordFormatModal: false })
              if (history.action == 'POP') {
                history.push('/')
              } else {
                history.goBack()
              }
            }}
          />
        )}
        {stage == 2 && showPatientListModal && (
          <RecordingStage2
            showNewPatientForm={newPatientStageTitlesShow}
            newPatientInit={record.new_patient}
            mainPatients={patients.byphone.main.users}
            addedPatients={patients.byphone.added.users}
            onSelect={(patient) => {
              this.setState({ showPatientListModal: false })
              recordPatientSetAction(patient)
              recordNewPatientSetAction(null)
              if (record.to_doctor) {
                recordStageSetAction(4)
                // @ts-ignore
                this.fetchDataForStage4ByDoctor({ patient })
              } else {
                recordStageSetAction(3)
                this.fetchDataForStage3()
              }
            }}
            onAdd={() => {
              this.setState({ newPatientStageTitlesShow: true })
            }}
            onCreate={(patient) => {
              this.setState({ showPatientListModal: false })
              recordPatientSetAction(null)
              recordNewPatientSetAction(patient)
              if (record.to_doctor) {
                recordStageSetAction(4)
                // @ts-ignore
                this.fetchDataForStage4ByDoctor({ new_patient: patient })
              } else {
                recordStageSetAction(3)
                this.fetchDataForStage3()
              }
            }}
          // TODO:
          // onCancel={() => {
          //   this.setState({ showPatientListModal: false })
          //   history.push('/')
          // }}
          // onSort={(sort) => {
          //   patientsByPhoneSortAction(sort.map(p => +p.patient_id))
          // }}
          />
        )}
        {stage == 3 && catalogsLoaded && (
          <RecordingStage3
            onSelect={(catalog) => {
              // @ts-ignore
              recordCatalogSetAction(catalog)
              this.fetchDataForStage4(catalog)
              recordStageSetAction(4)
            }}
            activeKey={activeCatalogKey}
            catalogs={catalogsByDepartments}
          />
        )}
        {stage == 4 && ((catalogServicesLoaded && record.type == 'bydate' && catalog) || (record.type == 'bydoctor' && doctorServicesLoaded)) && (
          <RecordingStage4
            activeKey={activeServicesKey}
            catalog_services={stage4_services.services.map(s => {
              const prices = activeServicesKey == '0' ? s.prices.filter(p => (p.is_allowed_for_this_patient ?? 1) && (p.allowrec ?? 1)) : s.prices
              // @ts-ignore
              return prices.length ? { ...s, prices } : undefined as CatalogItem
            }).filter(Boolean)}
            onSelect={(service) => {
              this.setState({ showRecordFormatModal: false })
	        
              this.fetchDataForStage5(service)
              // @ts-ignore
              recordServiceSetAction(service)
              recordStageSetAction(5)
            }}
            onPrepareInfo={(service) => {
              this.props.pricelistPrepareInfoRequestAction({ pls_id: service.pls_id })
              this.props.pricelistPrepareInfoModalSetAction({ visible: true })
            }}
          />
        )}
        {stage == 5 && ((record.type == 'bydate' && datetimesLoaded) || (record.type == 'bydoctor' && doctorsLoaded)) && (
          <RecordingStage5
            doctors_bydate={this.props.doctors_bydate}
            datetimes_bydate={this.props.datetimes_bydate}
            doctorsByDateLoaded={doctorsByDateLoaded}

            recordDoctor={record.doctor || undefined}
			recordPatient={record.patient || undefined}
            addedPatients={patients.byphone.added.users}
            doctors_bydoctor={this.props.doctors_bydoctor}
            datetimes_bydoctor={this.props.datetimes_bydoctor}

            recordType={record.type}
            appointments_future={this.props.appointments_future_by_service.appointments}
            appointments_future_added={this.props.appointments_future_by_service.appointments_added}
            
            onType={type => {
              this.props.recordTypeSetAction(type)
              if (type == 'bydate') {
                this.props.recordStateSetAction({ to_doctor: false })
              }
              this.props.recordDateTimeSetAction(null)
              this.props.recordDoctorSetAction(null)
              this.setState({ datetimesLoaded: false, doctorsLoaded: false }, () => {
                this.fetchDataForStage5(record.service as Price)
              })
            }}

            onDate={date => {
              // выбор дня в календаре
              const dateString = date.format('YYYY-MM-DD')
              this.fetchDoctorsByDateForStage5(dateString)
            }}
            onDateTime={datetime => {
              // выбор времени
              this.props.recordDateTimeSetAction(datetime)
            }}
            onDoctorByDate={doctor => {
              this.props.recordDoctorSetAction(doctor)
            }}
            onAddress={address => {
              this.props.recordAddressSetAction(address)
            }}

            onDoctor={doctor => {
              this.props.recordDoctorSetAction(doctor)
              this.setState({ datetimesByDoctorLoaded: false }, () => {
                this.fetchDatetimesByDoctorForStage5(doctor)
              })
            }}
            datetimesByDoctorLoaded={datetimesByDoctorLoaded}
            onChangeDoctor={() => {
              this.props.recordDoctorSetAction(null)
            }}

            onNext={() => {
              this.fetchRegisterInfoForStage6()
              this.props.recordStageSetAction(6)
            }}
          />)}
        {stage == 6 && this.state.registerInfoLoaded && (<RecordingStage6
          record={record}
          patients={patients.byphone}
          onSubmit={() => {
            this.registerAppointment()
          }}
          onPrepareInfo={() => {
            this.props.pricelistPrepareInfoRequestAction({ pls_id: record.service?.pls_id as number })
            this.props.pricelistPrepareInfoModalSetAction({ visible: true })
          }}
        />)}
        {appointmentRegistered && <Redirect to="/recording/success" />}
      </Template>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  error: state.error.message,
  patients: state.patients,
  patients_fetching: state.patients.byphone.main.fetching || state.patients.byphone.added.fetching,
  // patients: {
  //   fetching: state.patients.byphone.main.fetching || state.patients.byphone.added.fetching,
  //   users: [
  //     ...state.patients.byphone.main.users,
  //     ...state.patients.byphone.added.users,
  //     ...state.patients.bymistoken.main.users,
  //     ...state.patients.bymistoken.added.users,
  //   ].filter(uniquePatient),
  // },
  record: state.record,
  stage: state.record.stage,
  catalog: state.record.catalog,
  pricelist: state.pricelist,
  patient: state.record.patient,

  catalogs: state.pricelist.catalogs,
  catalog_services: state.pricelist.catalog_services,
  doctor_services: state.pricelist.doctor_services,
  appointments_future_by_service: state.appointments.future.byservice,

  doctors_bydate: state.schedule.bydate.divisions_doctors,
  datetimes_bydate: state.schedule.bydate.divisions_datetimes,

  doctors_bydoctor: state.schedule.bydoctor.doctors,
  datetimes_bydoctor: state.schedule.bydoctor.divisions_datetimes,

  register_info: state.register.info,
  appointment_register: state.register.appointment,
})

const uniquePatient = (user: IPatient, index: number, array: IPatient[]) => {
  return array.findIndex(u => u.medic_user_id == user.medic_user_id) == index
}

const mapDispatchToProps = {
  patientsByPhoneGetAction: patientsByphoneRequest,
  recordClearAction,
  recordPatientSetAction,
  recordNewPatientSetAction,
  recordFormatSetAction,
  patientsByPhoneSortAction: patientsByphoneOrderRequest,
  recordStageSetAction,
  recordCatalogSetAction,
  pricelistCatalogsGetAction,
  pricelistCatalogServivesGetAction,

  recordServiceSetAction,
  recordDateSetAction,
  recordDoctorSetAction,
  recordTypeSetAction,
  recordDateTimeSetAction,
  recordAddressSetAction,
  recordRegisterInfoSetAction,
  recordStateSetAction,

  pricelistCatalogServicesRequest,
  pricelistDoctorServicesRequestAction,
  pricelistCatalogsRequest,
  appointmentsFutureByServiceRequestAction: futureAppointmentsByServiceRequestAction,
  futureAppointmentsByServiceClearAction,
  scheduleByDateDivisionsDatetimesRequestAction,
  scheduleByDateDivisionsDoctorsRequestAction,
  scheduleByDoctorDoctorsRequestAction,
  scheduleByDoctorDivisionsDatetimesRequestAction,

  pricelistPrepareInfoRequestAction,
  pricelistPrepareInfoModalSetAction,

  registerAppointmentByMisTokenRequestAction,
  registerAppointmentByPhoneRequestAction,
  registerAppointmentMainRequestAction,

  registerInfoForNewPatientRequestAction,
  registerInfoForPatientRequestAction,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordingView))
