import LoginAPI from "../../../api/login.api";
import { AuthAction } from "./types";

export const sendCodeRequest = (params: { phone: string }): AuthAction => ({ type: 'AUTH_SEND_CODE_REQUEST', params })

export const sendCodeSuccess = (params?: { phone?: string }): AuthAction => ({ type: 'AUTH_SEND_CODE_SUCCESS', params })

export const sendCodeFailure = (): AuthAction => ({ type: 'AUTH_SEND_CODE_FAILURE' })

export const checkCodeRequest = (params: Parameters<LoginAPI['checkCode']>[0]): AuthAction => ({ type: 'AUTH_CHECK_CODE_REQUEST', params })

export const checkCodeSuccess = (params: { phone: string; token: string }): AuthAction => ({ type: 'AUTH_CHECK_CODE_SUCCESS', params })

export const checkCodeFailure = (): AuthAction => ({ type: 'AUTH_CHECK_CODE_FAILURE' })

export const logoutRequest = (): AuthAction => ({ type: 'AUTH_LOGOUT_REQUEST' })

export const logoutSuccess = (): AuthAction => ({ type: 'AUTH_LOGOUT_SUCCESS' })

export const logoutFailure = (): AuthAction => ({ type: 'AUTH_LOGOUT_FAILURE' })

export const authTokenSet = (token: string): AuthAction => ({ type: 'AUTH_TOKEN_SET', params: { token } })

export const checkAuthSession = (): AuthAction => ({ type: 'AUTH_CHECK_SESSION' })

export const authByLoginRequest = (params: { login: string; password: string }): AuthAction => ({ type: 'AUTH_BY_LOGIN_REQUEST', params })
export const authByLoginFailure = (error?: any): AuthAction => ({ type: 'AUTH_BY_LOGIN_FAILURE', params: error })
export const authByLoginSuccess = (): AuthAction => ({ type: 'AUTH_BY_LOGIN_SUCCESS' })

export const demoTokenRequest = (): AuthAction => ({ type: 'DEMO_TOKEN_REQUEST' })
export const demoTokenSuccess = (params: { token: string }): AuthAction => ({ type: 'DEMO_TOKEN_SUCCESS', params })
export const demoTokenFailure = (error?: any): AuthAction => ({ type: 'DEMO_TOKEN_FAILURE', params: error })