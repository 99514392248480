import React, { Component } from 'react'
import './style.less'
import { Button } from 'antd'
import Calendar from '../../Calendar'
import moment, { Moment } from 'moment'
import { ScheduleByDateDivisionsDoctorsState } from '../../../store/reducks/schedule/schedule.bydate.divisions_doctors.types'
import RecordingDepartmentDoctors from '../../RecordingDepartmentDoctors'
import { ScheduleByDateDivisionsDatetimesState } from '../../../store/reducks/schedule/schedule.bydate.divisions_datetimes.types'
import { RecordState } from '../../../store/reducks/record/record.types'
import { DoctorPreview } from '../../../api/doctor.types'
import { AppointmentFuture } from '../../../api/appointment.types'
import { ScheduleByDoctorDoctorsState } from '../../../store/reducks/schedule/schedule.bydoctor.doctors.types'
import { ScheduleByDoctorDivisionsDatetimesState } from '../../../store/reducks/schedule/schedule.bydoctor.divisions_datetimes.types'
import RecordingDoctors from '../../RecordingDoctors'
import RecordingDateTimesCard from '../../RecordingDateTimesCard'
import { DivisionDatetimes } from '../../../api/schedule.api.types'
import {Patient} from "../../../api/patient.types";

interface Props {
  onDate?: (date: Moment) => void
  onDateTime?: (datetime: string) => void
  onDoctor?: (doctor: DoctorPreview) => void
  onDoctorByDate?: (doctor: DoctorPreview) => void
  onAddress?: (address: string) => void
  onNext?: () => void
  onType?: (type: RecordState['type']) => void
  doctors_bydate: ScheduleByDateDivisionsDoctorsState
  datetimes_bydate: ScheduleByDateDivisionsDatetimesState
  doctorsByDateLoaded?: boolean
  appointments_future?: AppointmentFuture[]
  appointments_future_added?: AppointmentFuture[]
  doctors_bydoctor: ScheduleByDoctorDoctorsState
  datetimes_bydoctor: ScheduleByDoctorDivisionsDatetimesState
  datetimesByDoctorLoaded: boolean
  onChangeDoctor?: () => void
  recordDoctor?: DoctorPreview
  recordPatient?: Patient
  addedPatients: Patient[]
  recordType: RecordState['type']
}

interface State {
  selectedDateTime?: string
  selectedDate?: Moment
}

class RecordingStage5 extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.doctorsByDateLoaded && this.props.doctorsByDateLoaded) {
      this.setState({ selectedDateTime: undefined, selectedDate: undefined })
    }
  }

  private onDateTime = (datetime: string) => {
    const { onDateTime } = this.props
    onDateTime && onDateTime(datetime)
    this.setState({ selectedDateTime: datetime })
  }

  private onDoctorByDate = (doctor: DoctorPreview) => {
    const { onDoctorByDate } = this.props
    onDoctorByDate && onDoctorByDate(doctor)
  }

  private onAddress = (address: string) => {
    const { onAddress } = this.props
    onAddress && onAddress(address)
  }

  private onDate = (date: Moment) => {
    const { onDate } = this.props
    onDate && onDate(date)
    this.setState({ selectedDateTime: undefined, selectedDate: date })
  }

  private flattenDateTimesByDate = () => {
    const { datetimes_bydate } = this.props
    return datetimes_bydate.divisions.reduce((dates, division) => {
      const divisionDates = Object.keys(division.datetimes)
      dates.push(...divisionDates)
      return dates
    }, [] as string[])
      .filter((date, index, array) => array.indexOf(date) == index)
  }

  private flattenDateTimesByDoctor = () => {
    const { datetimes_bydoctor } = this.props
    return datetimes_bydoctor.divisions.reduce((dates, division) => {
      const divisionDates = Object.keys(division.datetimes)
      dates.push(...divisionDates)
      return dates
    }, [] as string[])
  }

  private renderFutureAppointments = () => {
    const { appointments_future, appointments_future_added, recordPatient, addedPatients } = this.props

	  if(appointments_future == null && appointments_future_added == null){
		  return null;
	  }
	  
	  return <div className="future-appointments">
		  <div className="future-appointments__header">Предстоящие записи на этот месяц</div>
		  <div className="future-appointments__body">
			  <div className="future-appointments__list">
				  {appointments_future != null && appointments_future.length > 0 ? <div className="future-appointments__item">
					  <div className="future-appointment">
						  <div className="future-appointment__name">
							  {recordPatient?.name}
						  </div>
						  <div className="future-appointment__records">
							  {appointments_future?.map(appointment => (<div key={appointment.appointment_id} className="future-appointment__record">
								  <span className="future-appointment__datetime">{moment(appointment.datetime).format('dd, DD MMM HH:mm')}</span>
								  <span className="future-appointment__doctor">
			            {` – Запись к врачу (${[appointment.doctor_name, appointment.doctor_service].filter(Boolean).join(' – ')})`}
			          </span>
							  </div>))}
						  </div>
					  </div>
				  </div> : ""}
				  {addedPatients.map((patient)=> {
					  appointments_future_added?.map((record)=>{
						  return <div className="future-appointments__item">
							  <div className="future-appointment">
								  <div className="future-appointment__name">
									  {patient.name}
								  </div>
								  <div className="future-appointment__records">
									  {appointments_future_added?.map(appointment => (<div key={appointment.appointment_id} className="future-appointment__record">
										  <span className="future-appointment__datetime">{moment(appointment.datetime).format('dd, DD MMM HH:mm')}</span>
										  <span className="future-appointment__doctor">
							            {` – Запись к врачу (${[appointment.doctor_name, appointment.doctor_service].filter(Boolean).join(' – ')})`}
							          </span>
									  </div>))}
								  </div>
							  </div>
						  </div>
					  })
				  })}
			  </div>
		  </div>
	  </div>
  }
	
	private getFutureAppointmentDates = () => {
		const {appointments_future} = this.props
		return appointments_future?.map(appointment => moment(appointment.datetime).format('YYYY-MM-DD'))
	}
	
	renderByDate = () => {
		const {doctors_bydate, doctorsByDateLoaded, onNext, recordDoctor} = this.props
		const {selectedDateTime} = this.state
		const {divisions} = doctors_bydate
		return <div className="bydate">
			<div className="calendar">
				<Calendar
					onSelect={this.onDate}
					dates={this.flattenDateTimesByDate()}
					marked={this.getFutureAppointmentDates()}
				/>
				{this.renderFutureAppointments()}
			</div>
			<div className="doctors">
				<strong>Врачи</strong>
				{doctorsByDateLoaded && divisions.map(division => (
					<RecordingDepartmentDoctors
						departmentAddress={division.name}
            doctorsWithTimes={division.doctors}
            onDateTime={this.onDateTime}
            onDoctor={this.onDoctorByDate}
            onAddress={this.onAddress}
            selectedDateTime={selectedDateTime}
            selectedDoctor={recordDoctor}
          />
        ))}
      </div>
      <div className="next">
        <Button type="primary" onClick={onNext} disabled={!selectedDateTime}>Далее</Button>
      </div>
    </div>
  }

  private renderByDoctor = () => {
    const { doctors_bydoctor, datetimes_bydoctor, datetimesByDoctorLoaded, recordDoctor, onNext } = this.props
    const { selectedDateTime, selectedDate } = this.state
    let doctor_datetimes: DivisionDatetimes[] = []
    if (selectedDate && datetimesByDoctorLoaded && recordDoctor) {
      doctor_datetimes = datetimes_bydoctor.divisions.filter(division => {
        const datetimes = division.datetimes[selectedDate.format('YYYY-MM-DD')]
        return Array.isArray(datetimes) && datetimes.length
      })
    }
    return <div className="bydoctor">
      <div className="doctors">
        <RecordingDoctors
          doctors={doctors_bydoctor.doctors}
          onChoose={this.onDoctor}
          onChange={this.onChangeDoctor}
          chosenDoctor={recordDoctor as DoctorPreview}
        />
      </div>
      {datetimesByDoctorLoaded && recordDoctor && (<>
        <strong>Выберите день</strong>
        <div className="calendar">
          <Calendar
            onSelect={this.onDate}
            dates={this.flattenDateTimesByDoctor()}
            marked={this.getFutureAppointmentDates()}
          />
          {this.renderFutureAppointments()}
        </div>
      </>)}
      {datetimesByDoctorLoaded && selectedDate && recordDoctor && (<div className="datetimes">
        <strong>{'Выберите время'}</strong>
        {doctor_datetimes.map(division => (
          <RecordingDateTimesCard
            key={division.id}
            datetimes={division.datetimes[selectedDate.format('YYYY-MM-DD')]}
            onDateTime={(datetime) => {
              this.onDateTime(datetime)
              this.onAddress(division.name)
            }}
            departmentAddress={division.name}
            selectedDateTime={selectedDateTime}
          />
        ))}
      </div>)}
      {datetimesByDoctorLoaded && recordDoctor && <div className="next">
        <Button type="primary" onClick={onNext} disabled={!selectedDateTime}>Далее</Button>
      </div>}
    </div>
  }

  private onDoctor = (doctor: DoctorPreview) => {
    const { onDoctor } = this.props
    onDoctor && onDoctor(doctor)
  }

  private onChangeDoctor = () => {
    const { onChangeDoctor } = this.props
    onChangeDoctor && onChangeDoctor()
  }

  private renderHeader = () => {
    return <div className="header">
      <strong>{this.getStateTitle()}</strong>
    </div>
  }

  private getStateTitle = () => {
    const { recordType } = this.props
    if (recordType == 'bydate') {
      return 'Выберите день'
    }
    if (recordType == 'bydoctor') {
      return 'Выберите врача'
    }
    return ''
  }

  render() {
    const {} = this.state
    const { recordType } = this.props
    return (
      <div className="recording-stage-fifth">
        {this.renderHeader()}
        {recordType == 'bydate' && this.renderByDate()}
        {recordType == 'bydoctor' && this.renderByDoctor()}
      </div>
    )
  }
}

export default RecordingStage5
