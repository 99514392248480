import { call, fork, put, select, take, takeLeading } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import ChatSocket from "../chat/chat.socket";
import { chatSocketConnectRequestAction } from "../chat/chat.socket.actions";
import { clearUser, updateUser } from "../user/user.actions";
import { selectUser } from "../user/user.utils";
import { authByLoginFailure, authByLoginSuccess, authTokenSet, checkCodeFailure, checkCodeSuccess, demoTokenFailure, demoTokenSuccess, logoutFailure, logoutRequest, logoutSuccess, sendCodeFailure, sendCodeSuccess } from "./auth.actions";
import { selectAuthPhone } from "./auth.utils";
import { AuthAction } from "./types";

export function* authMainSaga() {
  yield take('persist/REHYDRATE')
  yield call(legacyAuth)
  const user: ReturnType<typeof selectUser> = yield select(selectUser)
  if (user.is_demo) {
    yield put(clearUser())
  } else if (user.phone && user.token) {
    yield put(authTokenSet(user.token))
    yield put(chatSocketConnectRequestAction())
  }
  yield call(checkSessionSaga)
}

export function* legacyAuth() {
  const token = localStorage.getItem('accesstoken')
  const phone = localStorage.getItem('accessphone')
  if (token && phone) {
    yield put(updateUser({ phone, token }))
    localStorage.removeItem('accesstoken')
    localStorage.removeItem('accessphone')
  }
}

export function* checkSessionSaga() {
  const user: ReturnType<typeof selectUser> = yield select(selectUser)
  if (!user.phone || !user.token) {
    return
  }
  const res: ThenReturnType<typeof api.login.checkSession> = yield call(api.login.checkSession, { login: user.phone })
  if (res.error) {
    yield put(logoutRequest())
  }
}

export function* sendAuthCodeSaga(action: AuthAction) {
  const { phone } = action.params
  const res: ThenReturnType<typeof api.login.sendCode> = yield call(api.login.sendCode, { phone })
  if (res.error) {
    yield put(sendCodeFailure())
  } else {
    yield put(sendCodeSuccess({ phone }))
  }
}

export function* checkAuthCodeSaga(action: AuthAction) {
  const { phone, code } = action.params
  const res: ThenReturnType<typeof api.login.checkCode> = yield call(api.login.checkCode, { phone, code })
  if (res.error) {
    yield put(checkCodeFailure())
  } else {
    yield put(checkCodeSuccess({ phone, token: res.data.token }))
    yield put(chatSocketConnectRequestAction())
  }
}

export function* logoutSaga(action: AuthAction) {
  const phone: ReturnType<typeof selectAuthPhone> = yield select(selectAuthPhone)
  const res: ThenReturnType<typeof api.login.logout> = yield call(api.login.logout, { phone })
  if (res.error) {
    yield put(logoutFailure())
  } else {
    yield put(logoutSuccess())
    yield put({ type: 'STORAGE_CLEAR' })
    ChatSocket.disconnectAll()
  }
}

export function* authByLoginSaga(action: AuthAction) {
  const res: ThenReturnType<typeof api.login.authByLogin> = yield call(api.login.authByLogin, action.params)
  if (res.error) {
    yield put(authByLoginFailure())
  } else {
    yield put(authByLoginSuccess())
  }
}

export function* demoTokenRequestSaga(action: AuthAction) {
  const res: ThenReturnType<typeof api.login.getDemoToken> = yield call(api.login.getDemoToken)
  if (res.error) {
    yield put(demoTokenFailure())
  } else {
    yield put(demoTokenSuccess(res.data))
  }
}

export const dummyAction = () => ({ type: 'DUMMY_ACTION' })

export function* watchAuthSaga() {
  yield fork(authMainSaga)
  yield takeLeadingPattern('AUTH_SEND_CODE_REQUEST', sendAuthCodeSaga, sendCodeFailure)
  yield takeLeadingPattern('AUTH_CHECK_CODE_REQUEST', checkAuthCodeSaga, checkCodeFailure)
  yield takeLeadingPattern('AUTH_LOGOUT_REQUEST', logoutSaga, logoutFailure)
  yield takeLeadingPattern('AUTH_CHECK_SESSION', checkSessionSaga, dummyAction)
  yield takeLeadingPattern<AuthAction['type']>('AUTH_BY_LOGIN_REQUEST', authByLoginSaga, authByLoginFailure)
  yield takeLeadingPattern<AuthAction['type']>('DEMO_TOKEN_REQUEST', demoTokenRequestSaga, demoTokenFailure)
}