import React, { Component, ReactNode } from 'react'
import { BackIcon } from '../../icons'
import { Tabs } from 'antd'
import { Nullable } from '../../store/types'
import MenuIcon from '../../icons/MenuIcon.svg'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { menuCloseAction, menuOpenAction } from '../../store/reducks/menu/menu.actions'

interface Props extends ReduxConnectedProps {
  onTitleBack?: () => void
  onSubTitleBack?: () => void
  onTabChange?: (key: string) => void
  tabs?: string[]
  title: string
  children: ReactNode
  subTitle?: ReactNode | string
  headerFooter?: ReactNode | string
  activeKey?: string
  bodyRef?: (ref: HTMLDivElement | null) => any
}

class TemplateContent extends Component<Props, {}> {
  private header: Nullable<HTMLDivElement> | undefined

  private toggleMenu = () => {
    const { menu } = this.props
    if (menu.open) {
      this.props.menuCloseAction()
    } else {
      this.props.menuOpenAction()
    }
  }

  private renderHeader = () => {
    const { title, onTitleBack, onSubTitleBack, subTitle, headerFooter } = this.props
    return <div className="header" ref={(c) => (this.header = c)}>
      <div className="title">
        <div className="menu-icon" onClick={this.toggleMenu}>
          <img src={MenuIcon} />
        </div>
        {typeof onTitleBack === 'function' && (
          <div className="back" onClick={onTitleBack}>
            <BackIcon />
          </div>
        )}
        {title}
      </div>
      {subTitle && (
        <div className="subtitle">
          {typeof onSubTitleBack === 'function' && (
            <div className="back" onClick={onSubTitleBack}>
              <BackIcon />
            </div>
          )}
          {subTitle}
        </div>
      )}
      {this.renderTabs()}
      {headerFooter ? <div className="header-footer">{headerFooter}</div> : null}
    </div>
  }

  private renderTabs = () => {
    const { tabs, onTabChange, activeKey } = this.props
    return tabs && tabs.length > 0 && (
      <Tabs
        className="tabs"
        activeKey={activeKey}
        onChange={(activeKey) => {
          if (typeof onTabChange === 'function') onTabChange(activeKey)
        }}
      >
        {tabs.map((title: any, index: number) => {
          return <Tabs.TabPane tab={title} key={index.toString()} />
        })}
      </Tabs>
    )
  }

  private renderBody = () => {
    const { children } = this.props
    return <div
      className="body"
      ref={this.props.bodyRef}
      onScroll={({ target }) => {
        const { scrollTop } = target as HTMLDivElement
        if (!this.header) return
        if (scrollTop > 0) {
          this.header.classList.add('bordered')
        } else {
          this.header.classList.remove('bordered')
        }
      }}
    >
      {children}
    </div>
  }

  render() {
    const { menu } = this.props
    return (
      <div className={`template__content ${menu.open ? 'menu_open' : ''}`}>
        <div className="menu__background" onClick={this.props.menuCloseAction} />
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  }
}

const connector = connect(
  (state: AppState) => ({
    menu: state.menu,
  }), {
    menuOpenAction,
    menuCloseAction,
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(TemplateContent)
