import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import { ThenReturnType } from "../store/types";
import API, { RequestOptions } from "./api";
import { Patient } from "./patient.types";

export type LoginAPIMethodName = keyof LoginAPI
export type LoginAPIMethod = LoginAPI[LoginAPIMethodName]
export type LoginAPIMethodReturnType<T extends LoginAPIMethod = LoginAPIMethod> = ThenReturnType<T>

export type UserSession = {
  token: string
  appName: 'telemedicine' | 'medic' | 'lk'
  phone: string
  medic_user_id: number
  medic_version: string
  mymedic_version: string
  device_brand: string
  tm_device_brand: string
  tm_push_token: string
  push_token: string
}

class LoginAPI {
  private static SEND_SMS_CODE = '/send_sms_code' as const
  private static CHECK_SMS_CODE = '/check_sms_code' as const
  private static AUTH_BY_LOGIN = '/post_login_form' as const
  private static CHANGE_LOGIN = '/post_change_login' as const
  private static CHANGE_PASSWORD = '/post_change_password' as const
  private static LOGOUT = '/post_logout' as const
  private static GET_DEMO_TOKEN = '/get_demo_token' as const
  private static PASSWORD_RECOVERY_SEND_CODE = '/password_recovery_send_sms' as const
  private static PASSWORD_RECOVERY_CHECK_CODE = '/password_recovery_confirm_sms' as const
  private static RECOVER_PASSWORD = '/password_recovery' as const
  private static CHECK_SESSION = '/check_session' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  sendCode = async (params: { phone: string }) => {
    const res = await this.request<{ message: 'successful operation' }, { message: string }>({
      method: 'get',
      url: LoginAPI.SEND_SMS_CODE,
      params,
    })
    return res
  }

  checkCode = async (params: { code: string; phone: string }) => {
    const res = await this.request<{ token: string }, { message: string }>({
      method: 'get',
      url: LoginAPI.CHECK_SMS_CODE,
      params,
    })
    return res
  }

  authByLogin = async (data: { token?: string; login: string; password: string }) => {
    const res = await this.request<{ token: string; login: string; password: string; }, { message: string }>({
      method: 'post',
      url: LoginAPI.AUTH_BY_LOGIN,
      data: data,
    })
    return res
  }

  changeLogin = async (data: { token?: string; old_login: string; new_login: string }) => {
    const res = await this.request<{ message: string }>({
      method: 'post',
      url: LoginAPI.CHANGE_LOGIN,
      data,
    })
    return res
  }

  changePassword = async (data: { token?: string; old_password: string; new_password: string }) => {
    const res = await this.request<{ message: string }>({
      method: 'post',
      url: LoginAPI.CHANGE_PASSWORD,
      data,
    })
    return res
  }

  logout = async (data: { phone: string }) => {
    const res = await this.request<{ message: 'Successfully' }, { message: string }>({
      method: 'post',
      url: LoginAPI.LOGOUT,
      data,
    })
    return res
  }

  getDemoToken = async () => {
    const res = await this.request<{ token: string; }, { message: string }>({
      method: 'get',
      url: LoginAPI.GET_DEMO_TOKEN,
    })
    return res
  }

  sendPasswordRecoveryCode = async (params: { token?: string; phone: string | number }) => {
    const res = await this.request<{ message: string }>({
      method: 'get',
      url: LoginAPI.PASSWORD_RECOVERY_SEND_CODE,
      params,
    })
    return res
  }

  checkPasswordRecoveryCode = async (params: { token?: string; phone: string | number; code: string }) => {
    const res = await this.request<{ users: Patient[] }, { message: string }>({
      method: 'get',
      url: LoginAPI.PASSWORD_RECOVERY_CHECK_CODE,
      params,
    })
    return res
  }

  recoverPassword = async (params: { token?: string; phone: string | number; code: string; medic_user_id: number }) => {
    const res = await this.request<{ message: string }>({
      method: 'get',
      url: LoginAPI.RECOVER_PASSWORD,
      params,
    })
    return res
  }

  checkSession = async (params: { login: string }) => {
    const res = await this.request<UserSession, { message: string }>({
      method: 'get',
      url: LoginAPI.CHECK_SESSION,
      params,
    })
    return res
  }
}

export default LoginAPI