import React, { Component, Key, ReactNode } from 'react'
import TemplateMain from './main'
import TemplateHeader from './header'
import TemplateContent from './content'
import TemplateMenu from './menu'
import TemplateSidebar from './sidebar'
import './style.less'
import { table } from 'console'

interface Props {
  className: string
  onTitleBack?: () => void
  onSubTitleBack?: () => void
  onMenuItemClick?: (key: Key) => void
  onTabChange?: (key: string) => void
  title: string
  subTitle?: ReactNode | string
  sidebar?: ReactNode
  tabs?: string[]
  headerFooter?: ReactNode | string
  currentItem?: string
  bodyRef?: (ref: HTMLDivElement | null) => any
}

class Template extends Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      className,
      title,
      onTitleBack,
      onSubTitleBack,
      onMenuItemClick,
      onTabChange,
      children,
      sidebar,
      subTitle,
      tabs,
      headerFooter,
      currentItem,
    } = this.props
    return (
      <div className={className ? `template__layout ${className}` : 'template__layout'}>
        <TemplateMenu onMenuItemClick={onMenuItemClick} currentItem={currentItem} />
        <TemplateMain>
          <TemplateContent
            title={title}
            tabs={tabs}
            subTitle={subTitle}
            onTitleBack={onTitleBack}
            onSubTitleBack={onSubTitleBack}
            onTabChange={onTabChange}
            headerFooter={headerFooter}
            bodyRef={this.props.bodyRef}
          >
            {children}
          </TemplateContent>
        </TemplateMain>
        {sidebar && <TemplateSidebar>{sidebar}</TemplateSidebar>}
      </div>
    )
  }
}

export default Template
